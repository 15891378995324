@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Quicksand:wght@300;400;700&family=Rampart+One&family=Roboto+Mono:wght@100&family=Tenor+Sans&family=Tiro+Devanagari+Sanskrit&family=Titillium+Web:wght@300;400;700&family=Trocchi&display=swap');

body {
    font-family: lato;
    background-color: white;
}

.change-lang {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 4px;
    padding-bottom: 2px; */
    gap: 2.8px;
}



#change_language_none {
    display: none;
}

#change_language_contents {
    display: contents;
}

#change-lang {
    position: fixed; 
    width: 80px;
    left: 90%;
    top: 3%;
    /* bottom: 30px; */
    height: 30px;
    font-size: 15px;
    z-index: 1;
    background-color: #E1CCBE;
    border-radius: 4px;
    /* padding: 0vh 0vh 9vh 0vh; */
}

@media only screen and (max-width: 1150px) {
    #change-lang {
        left: 85%;
    }
}

@media only screen and (max-width: 750px) {
    #change-lang {
        left: 80%;
    }
}

@media only screen and (max-width: 550px) {
    #change-lang {
        left: 75%;
    }
}

#change-lang:hover {
    background-color: #f1e2d6;
}

.make-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

h1 {
    font-size: 40px;
}

h3 {
    color: black;
    font-weight: 400;
}

#logo2 {
    width: 250px;
}

.header {
    display: flex;
    flex-direction: column;
    background-image: url('./images/sarang_hero.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 10vh;
    
    
}

.logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3vh;
}

.logo2 {
    display: flex;
    align-items: center;
}

#logo {
    width: 50px;
}

.hero-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding-left: 7vh;
    padding-right: 7vh;
    padding-top: 35vh;
    gap: 15px;
}

.hero-logo > h3 {
    margin-bottom: 1vh;
}

.hero-text {
    color: black;
    font-size: 50px;
}

button {
    background-color: #E1CCBE;
    padding: 2.5vh;
    border: none;
    box-shadow: 0px 0px 2px black;
    color: black;
    font-size: 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

button:hover {
    cursor: pointer;
    background-color: #f1e6df;
    transform: scale(1.1);
}

/* #action-button {
    width: 150px;
    border-radius: 5px;
    background-color: #E1CCBE;
}

#action-button:hover {
    background-color: #f1e2d6;
} */

@media only screen and (max-width: 1160px) {
    button {
        padding: 1vh;
    }

    h1 {
        font-size: 30px;
    }

    h3 {
        font-size: 15px;
    }
}

.contact_button:hover {
    cursor: pointer;
    /* background-color: #da2f35; */
    opacity: .7;
    transition: all 0.25s ease-in-out;
    color: black;
}

.information {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13vh;
    gap: 40px;
}

.information_section1 {    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    object-fit: cover;
    max-width: 50%;
    max-height: 50%;
    min-height: 50%;
    min-width: 50%;
    background-color: rgb(247, 247, 247);
    padding-bottom: 50px;
    box-shadow: 0px 0px 4px black;

    /* justify-content: space-evenly; */
}

.information_section2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    object-fit: cover;
    max-width: 50%;
    max-height: 50%;
    min-height: 50%;
    min-width: 50%;
    background-color: rgb(247, 247, 247);
    padding-bottom: 50px;
    box-shadow: 0px 0px 4px black;

}

.information_paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    
}

#h3 {
    margin: 0;
}

.info_img {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#info_img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
}


.ourmission-paragraph {
    max-width: 60%;
    color: black;
    background-color: #E1CCBE;
    padding: 2vh;
    opacity: .7;
    border-radius: 5px;
}

#mission_statement {
    width: 65ch;
    text-align: center;
}

.mission_statement_header {
    padding: 3vh 3vh 0vh 3vh;
    text-align: center;
}


.mission_statement_paragraph > h3{
    color: black;
}

.mission_statement_header > h1{
    color: black;
}

.mission_statement_paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: rgb(247, 247, 247); */
    /* padding: 3vh; */
}

.services-container {
    background-color: #EEEDE9;
}

.services {
    padding: 0vh 7vh 0vh 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* box-shadow: 0px 0px 4px black; */
    /* margin-right: 10vh;
    margin-left: 10vh; */
}


.photo_grid {
    /* background-color: darkslategrey; */
    padding: 7vh 7vh 7vh 7vh;
    display: grid;
    gap: 7vh;
    align-content: space-evenly;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    /* max-width: 80%; */

}

#photo_grid {
    width: 280px;
    box-shadow: 0px 0px 4px black;
}

/* contact form section */
.contact-form {
    display: flex;
    padding: 7vh;
    justify-content: space-between;
    gap: 50px;
}

.contact-image {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

#contact-image {
    max-width: 100%;
}

.about-header {
    margin-top: 0vh;
}

.contact-page {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

input {
    border: solid;
    border-width: 1px;
}

textarea {
    border: solid;
    border-width: 1px;
    font-family: lato;
    font-size: 20px;
}

.contact-input {
    font-size: 16px;
}

#contact-input3 {
    width: 484px;
}

#contact-input2 {
    padding: 2vh;
    width: 484px;
    
}

.name {
    display: flex;
    gap: 50px;
}

input {
    padding: 2vh;
    width: 200px;
    font-size: 20px;
}

#logo_footer {
    width: 250px;
}

.contact {
    display: flex;
    justify-content: space-between;
    padding: 7vh;
    background-color: #EEEDE9;
}

.footer-phone {
    text-decoration: none;
    color: black;
    padding-bottom: 2.3px;
}


.footer-info {
    display: flex;
    align-items: center;
    gap: 4px;
}

.item1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/* --------------------- korean text ----------------------------------- */

#k-text-h3 {
    margin-top: 0;
    color: #01459B;
}

#k-text2-h3 {
    color: black;
    text-align: left;
    /* font-size: 40px; */
    margin-bottom: 0;
}

#k-text-h1 {
    margin-bottom: 0;
}

#k-text2-h1 {
    margin-top: 0;
    color: #01459B;
}

#k-text-h3-mission {
    margin-top: 0;
    color: #01459B;
    width: 65ch;
    text-align: center;
}

.footer-info-parent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media only screen and (max-width: 440px) {
    #k-text-h3-mission {
        width: 40ch;
    }

    #hero-text {
        font-size: 30px;
    }


    .contact {
        display: flex;
        flex-direction: column;
        gap: 25px; /* Add some gap between items if needed */
    }
}

/* @media only screen and (min-width: 2600px) {
    #photo_grid {
        width: 300px;
    }
} */


@media only screen and (min-width: 1600px) {
    h1 {
        font-size: 70px;
    }

    h3 {
        
    }

    #photo_grid {
        width: 400px;
    }

    
}    

@media only screen and (max-width: 1050px) {
    #logo2 {
        width: 200px;
    }

    .hero-text {
        font-size: 35px;
    }

    .information {
        padding: 10vh;
    }

    #photo_grid {
        width: 200px;
    }

    .contact {
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: auto; /* Ensure rows adjust height based on content */
        gap: 25px; /* Add some gap between items if needed */
    }

    /* #iframe {
        width: none;
    }

    .iframe {
        width: none;
    } */
    
    
}

@media only screen and (max-width: 850px){
    .photo_grid {
        grid-template-columns: auto auto;
        /* gap: 5vh; */
        column-gap: 0vh;
    }

    #photo_grid {
        width: 250px;
    }
}

@media only screen and (max-width: 660px){
    .photo_grid {
        grid-template-columns: auto auto;
        /* gap: 5vh; */
        column-gap: 0vh;
    }

    #photo_grid {
        width: 190px;
    }
}

@media only screen and (max-width: 440px){
    #logo {
        width: 40px;
    }

    .header {
        background-size: 500px !important;
        padding-bottom: 0vh !important;
    }

    .logo {

    }

    #logo2 {
        width: 120px;
    }

    .hero-logo {
        padding-top: 15vh !important;
        padding: 2vh;
        width: 240px !important;
    }

    .hero-text {
        font-size: 25px;
        
    }


    button {
        padding: 1vh;
    }

    .contact_button {
        font-size: 10px;
    }

    .hero-logo {
        padding-top: 10vh;
    }

    .header {
        background-size: 550px;
        padding-bottom: 3vh;
        background-size: contain;
    }

    h1 {
        margin-bottom: 5px;
        font-size: 25px;
    }

    .information {
        flex-direction: column;
        padding: 3vh;
    }

    .information_section1 {
        max-width: 95%;
        max-height: 95%;
        min-height: 95%;
        min-width: 95%;
        padding-bottom: 20px;
    }

    .information_section2 {
        max-width: 95%;
        max-height: 95%;
        min-height: 95%;
        min-width: 95%;
        padding-bottom: 20px;
    }

    .information_paragraph {
        padding-top: 0vh;
    }

    .mission_statement {
        padding: 5vh;
    }

    #mission_statement {
        width: 40ch;
    }

    .parent_photo_grid {
        padding: 6vh 2vh 6vh 2vh;
    }

    .photo_grid {
        padding: 0vh;
        gap: 3vh;
        justify-content: space-evenly;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
    }

    #photo_grid {
        width: 150px;
    }

    .contact {
        display: flex;
        padding: 8vh 3vh 8vh 3vh !important;
        gap: 45px;
        /* flex-direction: column; */
        padding: 3vh;
    }

    .contact-form {
        padding: 5vh 2vh 5vh 2vh;
    }

    input {
        width: 100px !important;
        height: 10px;
    }

    #contact-input2 {
        width: 279px !important;
        
    }

    #contact-input3 {
        width: 279px !important;
        height: 10px;
    }

    .contact-form {
        flex-direction: column;
    }

    .contact {
        padding:  3vh 2vh 8vh 2vh !important;
    }
}

/* contact component responsiveness */
@media only screen and (max-width: 920px){
    input {
        width: 120px;
    }

    #contact-input2 {
        width: 325px;
    }

    #contact-input3 {
        width: 325px;
    }
}

@media only screen and (max-width: 770px){
    input {
        width: 220px;
    }

    #contact-input2 {
        width: 524px;
    }

    #contact-input3 {
        width: 524px;
    }

    .contact-form {
        flex-direction: column;
    }
}

@media only screen and (max-width: 660px){
    input {
        width: 180px;
    }

    .contact-image {
        max-width: 80%;
    }

    #contact-input2 {
        width: 446px;
    }

    #contact-input3 {
        width: 446px;
    }

    .contact-form {
        flex-direction: column;
    }
}

@media only screen and (max-width: 580px){
    input {
        width: 120px;
    }

    .contact-image {
        max-width: 80%;
    }

    #contact-input2 {
        width: 325px;
    }

    #contact-input3 {
        width: 325px;
    }

    .contact-form {
        flex-direction: column;
    }
}

@media only screen and (max-width: 685px){

    .contact {
        display: flex;
        align-items: center;
        flex-direction: column !important;
        gap: 50px;
        text-align: center;
    }
}