#iframe {
    width: 460px;
    height: 200px;
    border: none;
    /* frame-border : "0"; 
    scrolling: "no"; 
    margin-height: "0"; 
    margin-width: "0"; */
}



.iframe {
    /* width: 40%; */
    /* box-shadow: 
                10px 0 10px -10px rgba(0, 0, 0, 0.5),
                -10px 0 10px -10px rgba(0, 0, 0, 0.5)
                ; */
}

@media only screen and (max-width: 915px){

    #iframe {
        width: 300px;
    }
}

@media only screen and (max-width: 665px){

    .contact {
        flex-direction: column;
    }
}