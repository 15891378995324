.header_paragraph_container {
    display: flex;
    flex-direction: column;
    padding: 7vh 7vh 70vh 7vh;
    justify-content: space-between;
    gap: 10px;
    background-color: white;
    background-image: url('../images/1140-woman-stretching.jpg');
    background-size: cover;
}

@media only screen and (max-width: 1260px) {
    .header_paragraph_container{
        padding: 7vh 7vh 50vh 7vh;
        /* background-size: cover; */
    } 
}

@media only screen and (max-width: 990px) {
    .header_paragraph_container{
        padding: 7vh 7vh 35vh 7vh;
        /* background-size: cover; */
    } 
}

@media only screen and (max-width: 725px) {
    .header_paragraph_container{
        padding: 7vh 7vh 28vh 7vh;
        gap: 10px;
        /* background-size: cover; */
    } 
}

@media only screen and (max-width: 650px) {
    .header_paragraph_container{
        padding: 7vh 7vh 22vh 7vh;
        gap: 10px;
        /* background-size: cover; */
    } 
}

.header_paragraph_container > .ourmission {
    min-width: 50%;
}

.header_paragraph_container > h3 {
}

.header_paragraph_container > h3 {
}

@media only screen and (max-width: 440px) {
    .header_paragraph_container{
        flex-direction: column;
        padding: 5vh 2vh 5vh 2vh;
        gap: 0px;
    } 
}