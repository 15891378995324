.picture_paragraph {
    display: flex;
    flex-direction: column;
}

.picture_paragraph > h1 {
    font-size: 50px;
}

.picture_paragraph > h3 {
    /* background-color: #E1CCBE;
    padding: 2.5vh;
    border: none;
    box-shadow: 0px 0px 2px black; */
    color: black;
    font-size: 25px;
    font-weight: 400;
    /* width: 45px; */
}

.picture_paragraph_container {
    display: flex;
    gap: 50px;
    padding: 7vh;
    background-color: #EEEDEA;
}

@media only screen and (max-width: 640px) {
    .picture_paragraph_container {
        flex-direction: column;
    }

    .picture > img {
        display: flex;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
    }

    .picture_paragraph_container > .picture {
        display: flex;
        max-height: 100%;
        max-width: 100%;
    }
}


.picture_paragraph_container > .picture_paragraph {
    /* min-width: 50%; */
    max-width: 50%;
}

.picture_paragraph_container > .picture {
    max-height: 50%;
    max-width: 50%;
}

.picture > img {
    object-fit: cover;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    box-shadow: 0px 0px 2px black;
}

@media only screen and (max-width: 440px) {
    .picture_paragraph > h1 {
        font-size: 20px;
    }
    
    .picture_paragraph > h3 {
        font-size: 15px;
    }

    .picture_paragraph_container {
        padding: 4vh 2vh 5vh 2vh;
        gap: 15px;
    }
}